// import Swiper from 'swiper';
// import 'remodal';

// スクロール
var Ease = {
	easeInOut: (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
};
var duration = 500;
window.addEventListener('DOMContentLoaded', () => {
	var smoothScrollTriggers = document.querySelectorAll('.anchor');
	smoothScrollTriggers.forEach(function (smoothScrollTrigger) {
		smoothScrollTrigger.addEventListener('click', function (e) {
			var href = smoothScrollTrigger.getAttribute('href');
			var currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
			var targetElement = document.getElementById(href.replace('#', ''));
			if (targetElement) {
				e.preventDefault();
				e.stopPropagation();
				var targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - 48;
				var startTime = performance.now();
				var loop = function (nowTime) {
					var time = nowTime - startTime;
					var normalizedTime = time / duration;
					if (normalizedTime < 1) {
						window.scrollTo(0, currentPostion + (targetPosition - currentPostion) * Ease.easeInOut(normalizedTime));
						requestAnimationFrame(loop);
					} else {
						window.scrollTo(0, targetPosition);
					}
				};
				requestAnimationFrame(loop);
			}
		});
	});
});

window.addEventListener('load', () => {
	//アニメーション
	funcAnim();

	window.addEventListener('scroll', () => {
		//アニメーション
		funcAnim();
	});
});

function funcAnim() {
	let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	let myMain = document.querySelectorAll('.anim');
	for (let i = 0; i < myMain.length; i++) {
		let rect = myMain[i].getBoundingClientRect();
		let posiTop = rect.top + scrollTop; // ターゲットがブラウザTOPに来たら
		// let posiMid = posiTop - window.innerHeight / 2; // ターゲットがブラウザMIDに来たら
		let posiBtm = posiTop - window.innerHeight; // ターゲットがブラウザBOTOMに来たら
		let myHeight = myMain[i].clientHeight - 100;
		let position_bottom = posiTop + myHeight; // ターゲットが下からスクロールしたときに見える位置

		// ターゲットが来た時ときの動き
		if (scrollTop > posiBtm && scrollTop < position_bottom) {
			myMain[i].classList.add('active');
			// console.log('きた');
		}
		// else {
		// 	// それ以外の動き
		// 	myMain[i].classList.remove('active');
		// }
	}
}
